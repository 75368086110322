<template>
  <div class="register">
    <div class="flex-center py-12">
      <icon name="logo" class="is-100x100"></icon>
    </div>
    <div class="register__form">
      <h6 class="text-h5 mb-4">Create New Account</h6>
      <v-text-field
        ref="name"
        v-model="name"
        :rules="[rules.required]"
        label="Your Name"
        dense
        required
        autofocus
        outlined
        autocomplete="off"
        class="mt-7 mb-3"
      ></v-text-field>
      <v-text-field
        ref="number"
        v-model="number"
        :rules="[
          () =>
            (number && number.length == 10) ||
            'Phone number should be 10 characters',
        ]"
        label="Your Number"
        type="number"
        dense
        required
        counter
        autocomplete="off"
        maxlength="10"
        outlined
        class="mb-3"
      ></v-text-field>
      <v-text-field
        ref="email"
        v-model="email"
        :rules="[rules.required, rules.email]"
        label="Your Email"
        dense
        required
        autocomplete="off"
        outlined
        class="mb-3"
      ></v-text-field>
      <v-dialog
        ref="birth_modal"
        v-model="birth_modal"
        :return-value.sync="birth_date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            ref="date_of_birth"
            v-model="date_of_birth"
            label="Date of Birth"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="[rules.required]"
            required
            outlined
            dense
            class="mb-3"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date_of_birth"
          :active-picker.sync="birthPicker"
          :max="
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10)
          "
          min="1800-01-01"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="birth_modal = false">
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.birth_modal.save(date_of_birth)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
      <v-text-field
        ref="password"
        v-model="password"
        :rules="[rules.required]"
        label="Your Password"
        :type="show_password ? 'text' : 'password'"
        dense
        required
        outlined
        autocomplete="off"
        class="mb-3"
      ></v-text-field>
      <v-radio-group v-model="gender" row class="mb-3">
        <template v-slot:label>
          <span class="mr-2">About You:</span>
        </template>
        <v-radio value="male">
          <template v-slot:label>
            <div>
              Male
            </div>
          </template>
        </v-radio>
        <v-radio value="female">
          <template v-slot:label>
            <div>
              Female
            </div>
          </template>
        </v-radio>
      </v-radio-group>

      <div @click="register" class="kvk-button mb-7">Register</div>
      <router-link to="/login" class="flex-center is-link">
        <span class="helper-text">Already have an account?</span>
        <span class="accent-text ml-2 fw-500 ls-05">Login</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import {
  auth,
  createUserWithEmailAndPassword,
  updateProfile,
  db,
  doc,
  serverTimestamp,
  setDoc,
} from "@/firebaseconfig.js";

export default {
  data() {
    return {
      name: "",
      number: null,
      email: "",
      date_of_birth: "",
      password: "",
      error: null,
      birthPicker: null,
      show_password: false,
      birth_modal: false,
      menu: false,
      birth_date: null,
      gender: "male",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  watch: {
    birth_modal(val) {
      val && setTimeout(() => (this.birthPicker = "YEAR"));
    },
  },
  methods: {
    register() {
      this.is_loading = true;
      createUserWithEmailAndPassword(auth, this.email, this.password)
        .then((data) => {
          updateProfile(auth.currentUser, {
            displayName: this.name,
            phoneNumber: this.number,
            dateOfBirth: this.date_of_birth,
          }).then(async () => {
            this.$store.commit("SET_LOGGED_USER", data.user);
            const docRef = await setDoc(
              doc(db, "members_list", data.user.uid),
              {
                added_time: serverTimestamp(),
                name: this.name,
                number: this.number,
                email: this.email,
                date_of_birth: this.date_of_birth,
                date_of_death: null,
                status: "active",
                living: true,
                user_type: "user",
                password: this.password,
                gender: this.gender,
                id: data.user.uid,
                config: { theme: "light", currency: "INR" },
                // guest
                // user
                // admin
                // superadmin
              }
            );

            // await updateDoc(doc(memberCollection, docRef.id), {
            //   id: docRef.id,
            //   config: { theme: "light", currency: "INR" },
            // });

            this.is_loading = false;
            this.$router.replace({ name: "Home" });
          });
        })
        .catch((err) => {
          this.is_loading = false;
          this.error = err.message;
          console.log(this.error);
        });
    },
    // save(date) {
    //   this.$refs.menu.save(date);
    // },
  },
};
</script>
<style lang="scss" scoped>
.register {
  background-color: $primary;
  height: 100vh;
  &__form {
    background-color: #fff;
    height: 100%;
    border-radius: 1.25rem 1.25rem 0 0;
    padding: 1.25rem;
  }
}
</style>
