// Reference link for the format
// https://moment.github.io/luxon/docs/manual/formatting

// PRESET FORMAT
// ================================================================================================
// DATE_SHORT                          10/14/1983
// DATE_MED                            Oct 14, 1983
// DATE_MED_WITH_WEEKDAY               Fri, Oct 14, 1983
// DATE_FULL                           October 14, 1983
// DATE_HUGE                           Tuesday, October 14, 1983
// TIME_SIMPLE                         1:30 PM
// TIME_WITH_SECONDS                   1:30:23 PM
// TIME_WITH_SHORT_OFFSET              1:30:23 PM EDT
// TIME_WITH_LONG_OFFSET               1:30:23 PM Eastern Daylight Time
// TIME_24_SIMPLE                      13:30
// TIME_24_WITH_SECONDS                13:30:23
// TIME_24_WITH_SHORT_OFFSET           13:30:23 EDT
// TIME_24_WITH_LONG_OFFSET            13:30:23 Eastern Daylight Time
// DATETIME_SHORT                      10/14/1983, 1:30 PM
// DATETIME_MED                        Oct 14, 1983, 1:30 PM
// DATETIME_FULL                       October 14, 1983, 1:30 PM EDT
// DATETIME_HUGE                       Friday, October 14, 1983, 1:30 PM Eastern Daylight Time
// DATETIME_SHORT_WITH_SECONDS         10/14/1983, 1:30:23 PM
// DATETIME_MED_WITH_SECONDS           Oct 14, 1983, 1:30:23 PM
// DATETIME_FULL_WITH_SECONDS          October 14, 1983, 1:30:23 PM EDT
// DATETIME_HUGE_WITH_SECONDS          Friday, October 14, 1983, 1:30:23 PM Eastern Daylight Time

//  Examples
//  ================================================================================================
//  {{ selectedForm.createdAt | prettyDateFormat }}
//  {{ selectedForm.createdAt | prettyDateFormat("DATE_HUGE") }}

// import { DateTime } from "luxon";
var DateTime = luxon.DateTime;

export default function(date, preset, cus_format, hasTimeZone = true) {
  if (!date) return "";
  if (preset == "CUSTOM") {
    let format = cus_format ? cus_format : "ccc , DD";
    // let format = preset ? preset : "DATETIME_MED";
    // let format = preset ? preset : "ff";
    return DateTime.fromISO(date).toFormat(format);
  } else {
    let format = preset ? preset : "DATETIME_MED";
    console.log("else");
    return DateTime.fromISO(
      date,
      hasTimeZone
        ? {
            setZone: true,
            zone: "utc",
          }
        : {}
    ).toLocaleString(DateTime[format]);
  }
}
