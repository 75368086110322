<template>
  <svg ref="ico" viewBox="0 0 100 100">
    <use :href="src + '#' + name" />
  </svg>
</template>
<script>
export default {
  props: ["name", "file"],
  computed: {
    src() {
      // let firefox = typeof InstallTrigger !== "undefined";
      // if (firefox || (this.$route.meta.screen == "mobile" && this.isSafari)) {
      //   this.safariFix(this.name);
      // }
      return require(`@/assets/img/sprites/${
        this.file ? this.file : "sprite"
      }.svg`);
    },
    // isSafari() {
    //   let safari = navigator.userAgent.indexOf("Safari") > -1;
    //   let chrome = navigator.userAgent.indexOf("Chrome") > -1;
    //   return safari && !chrome;
    // },
  },
};
</script>
