<template>
  <v-dialog v-model="deferredPrompt" persistent width="325">
    <v-card>
      <v-card-title class="text-h5">
        Do you want to install the App ?
      </v-card-title>
      <v-card-text
        >Get our free app. It won't take up space on your phone and also we are
        not storing your personal information!</v-card-text
      >
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn @click="deferredPrompt = null" color="primary darken-1" text>
          Cancel
        </v-btn>
        <v-btn color="green darken-1" text @click="install">
          Install
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      deferredPrompt: null,
    };
  },
  mounted() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  methods: {
    async install() {
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          // Call another function?
          console.log("PWA Installed Successfully");
        }
        this.deferredPrompt = null;
      });
      // this.deferredPrompt.prompt();
    },
  },
};
</script>

<style lang="scss" scoped></style>
