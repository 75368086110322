<template>
  <div id="app">
    <v-app class="app-wrapper">
      <install-popup />
      <app-loader />
      <router-view></router-view>
    </v-app>
  </div>
</template>

<script>
import InstallPopup from "@/components/shared/ui/install-popup.vue";
import AppLoader from "@/components/shared/ui/app-loader.vue";
export default {
  components: {
    "install-popup": InstallPopup,
    "app-loader": AppLoader,
  },
  data() {
    return {
      deferredPrompt: true,
    };
  },
  mounted() {
    const theme = localStorage.getItem("theme");
    if (theme) {
      if (theme == "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    }
  },
  methods: {
    setTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("theme", this.$vuetify.theme.dark.toString());
    },
  },
  watch: {
    "$route.name": {
      handler() {
        console.log("route name-----", this.$route.name);
        // if (this.$route.meta.sidebar == "expand") {
        //   this.$store.commit("SET_SIDEBAR_MINI_VARIANT", false);
        // } else if (this.$route.meta.sidebar == "collapse") {
        //   this.$store.commit("SET_SIDEBAR_MINI_VARIANT", true);
        // }
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
#app {
  font-family: "Roboto", sans-serif;
  font-size: pxr(14px);
  color: $primary-text;
}
html,
body {
  padding: 0;
  margin: 0;
  color: $primary-text;
  // background: #f0f3f6 !important;
  background: #ededf5 !important;
}
// .app-wrapper {
//   background: #f00;
//   // background: #e7eaee;
// }
.theme--light.v-application {
  // background: #f0f3f6 !important;
  background: #ededf5 !important;
}
.loader-card {
  border-radius: 4px;
  background-color: $white;
  display: grid;
  place-items: center;
  padding: 4.5rem 0;
  color: $disabled-text;
}
</style>
