var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register"},[_c('div',{staticClass:"flex-center py-12"},[_c('icon',{staticClass:"is-100x100",attrs:{"name":"logo"}})],1),_c('div',{staticClass:"register__form"},[_c('h6',{staticClass:"text-h5 mb-4"},[_vm._v("Create New Account")]),_c('v-text-field',{ref:"name",staticClass:"mt-7 mb-3",attrs:{"rules":[_vm.rules.required],"label":"Your Name","dense":"","required":"","autofocus":"","outlined":"","autocomplete":"off"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{ref:"number",staticClass:"mb-3",attrs:{"rules":[
        function () { return (_vm.number && _vm.number.length == 10) ||
          'Phone number should be 10 characters'; } ],"label":"Your Number","type":"number","dense":"","required":"","counter":"","autocomplete":"off","maxlength":"10","outlined":""},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}}),_c('v-text-field',{ref:"email",staticClass:"mb-3",attrs:{"rules":[_vm.rules.required, _vm.rules.email],"label":"Your Email","dense":"","required":"","autocomplete":"off","outlined":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-dialog',{ref:"birth_modal",attrs:{"return-value":_vm.birth_date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.birth_date=$event},"update:return-value":function($event){_vm.birth_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({ref:"date_of_birth",staticClass:"mb-3",attrs:{"label":"Date of Birth","readonly":"","rules":[_vm.rules.required],"required":"","outlined":"","dense":""},model:{value:(_vm.date_of_birth),callback:function ($$v) {_vm.date_of_birth=$$v},expression:"date_of_birth"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.birth_modal),callback:function ($$v) {_vm.birth_modal=$$v},expression:"birth_modal"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.birthPicker,"max":new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),"min":"1800-01-01"},on:{"update:activePicker":function($event){_vm.birthPicker=$event},"update:active-picker":function($event){_vm.birthPicker=$event}},model:{value:(_vm.date_of_birth),callback:function ($$v) {_vm.date_of_birth=$$v},expression:"date_of_birth"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.birth_modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.birth_modal.save(_vm.date_of_birth)}}},[_vm._v(" OK ")])],1)],1),_c('v-text-field',{ref:"password",staticClass:"mb-3",attrs:{"rules":[_vm.rules.required],"label":"Your Password","type":_vm.show_password ? 'text' : 'password',"dense":"","required":"","outlined":"","autocomplete":"off"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-radio-group',{staticClass:"mb-3",attrs:{"row":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"mr-2"},[_vm._v("About You:")])]},proxy:true}]),model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}},[_c('v-radio',{attrs:{"value":"male"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Male ")])]},proxy:true}])}),_c('v-radio',{attrs:{"value":"female"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Female ")])]},proxy:true}])})],1),_c('div',{staticClass:"kvk-button mb-7",on:{"click":_vm.register}},[_vm._v("Register")]),_c('router-link',{staticClass:"flex-center is-link",attrs:{"to":"/login"}},[_c('span',{staticClass:"helper-text"},[_vm._v("Already have an account?")]),_c('span',{staticClass:"accent-text ml-2 fw-500 ls-05"},[_vm._v("Login")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }