import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // user: {
    //   loggedIn: false,
    //   data: null,
    // },
    logged_user: null,
    claims: null,
    is_content_loading: false,
    all_members: null,
    all_members_list: null,
  },
  getters: {
    // user(state) {
    //   return state.user;
    // },
  },
  mutations: {
    // SET_LOGGED_IN(state, value) {
    //   state.user.loggedIn = value;
    // },
    SET_LOGGED_USER(state, data) {
      state.logged_user = data;
    },
    SET_CLAIMS(state, data) {
      state.claims = data;
    },
    SET_IS_CONTENT_LOADING(state, value) {
      state.is_content_loading = value;
    },
    SET_ALL_MEMBERS(state, value) {
      state.all_members = value;
    },
    SET_ALL_MEMBERS_LIST(state, value) {
      state.all_members_list = value;
    },
  },
  actions: {
    // fetchUser({ commit }, user) {
    //   commit("SET_LOGGED_IN", user !== null);
    //   if (user) {
    //     commit("SET_USER", {
    //       displayName: user.displayName,
    //       email: user.email,
    //     });
    //   } else {
    //     commit("SET_USER", null);
    //   }
    // },
  },
  modules: {},
});
