import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
      // themeCache: {
      //   // get: key => localStorage.getItem(key),
      //   // set: (key, value) => localStorage.setItem(key, value),
      //   get: key => localStorage.getItem(key),
      //   set: (key, value) => localStorage.setItem(key, value),
      // },
    },
    themes: {
      light: {
        primary: "#1f6cff",
        secondary: "#424242",
        accent: "#ff9300",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        // border: "#B6B6B6",
      },
      dark: {
        primary: "#1f6cff",
        secondary: "#424242",
        accent: "#ff9300",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        // border: "#B6B6B6",
      },
    },
  },
  icons: {
    iconfont: "fa",
  },
});
