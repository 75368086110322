import { initializeApp } from "firebase/app";
import {
  getFirestore,
  serverTimestamp,
  doc,
  collection,
  addDoc,
  setDoc,
  getDocs,
  getDoc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  query,
  orderBy,
  limit,
  where,
} from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

// import { ref, onUnmounted } from "vue";

/* ----------------------------- Firebase Config ---------------------------- */
const firebaseConfig = {
  apiKey: "AIzaSyAg4tAUtPcKEZqb_Y5hAjL0Gn20ceyXJsI",
  authDomain: "app-kvk.firebaseapp.com",
  projectId: "app-kvk",
  storageBucket: "app-kvk.appspot.com",
  messagingSenderId: "1001810802617",
  appId: "1:1001810802617:web:bc421e2410df9a2c0d6868",
  measurementId: "G-L8SWP3KNNX",
};

/* --------------------------- Initialize Firebase -------------------------- */
const fb = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore();

const memberCollection = collection(db, "members_list");
const eventsCollection = collection(db, "events");
const financeAllCollection = collection(db, "finance_all");
const incomeCollection = collection(db, "finance_income");
const expenseCollection = collection(db, "finance_expense");
const memberIncomeCollection = collection(db, "finance_member_income");
/* -------------------------------- Exporting ------------------------------- */
export {
  fb,
  auth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword,
  signOut,
  db,
  doc,
  collection,
  financeAllCollection,
  memberCollection,
  eventsCollection,
  incomeCollection,
  expenseCollection,
  memberIncomeCollection,
  serverTimestamp,
  getDocs,
  getDoc,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  sendPasswordResetEmail,
  query,
  orderBy,
  getStorage,
  getDownloadURL,
  ref,
  limit,
  where,
};

// /* ------------------------------ Create / Add ------------------------------ */
// // export const createMember = (member) => {
// //   return await addDoc(memberCollection, {
// //     name: "Tokyo",
// //     country: "Japan"
// //   });
// // };
// export const createMember = (member) => {
//   return addDoc(memberCollection, member);
// };
// /* ---------------------------------- Read ---------------------------------- */
// // export const getAllMember = (id) => {
// //   const querySnapshot = getDocs(memberCollection);
// //   querySnapshot.forEach((doc) => {
// //     // doc.data() is never undefined for query doc snapshots
// //     console.log(doc.id, " => ", doc.data());
// //   });
// //   // return getDoc(doc(db, "members_list", id));
// // };
// export const getMember = (id) => {
//   return getDoc(doc(db, "members_list", id));
// };
// /* --------------------------------- Update --------------------------------- */
// export const updateMember = (id, member) => {
//   return updateDoc(doc(db, "members_list", id), member);
// };
// /* --------------------------------- Delete --------------------------------- */
// export const deleteMember = (id) => {
//   return deleteDoc(doc(db, "members_list", id));
// };

// // export const useLoadUsers = () => {
// //   const members = ref([]);
// //   const close = getDocs(memberCollection).forEach((snapshot) => {
// //     members.value = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
// //   });

// //   // .querySnapshot(snapshot => {
// //   //   members.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
// //   // })
// //   //   const querySnapshot = getDocs(memberCollection);
// //   // querySnapshot.forEach((doc) => {
// //   //   // doc.data() is never undefined for query doc snapshots
// //   //   console.log(doc.id, " => ", doc.data());
// //   // });
// //   onUnmounted(close);
// //   return members;
// // };
