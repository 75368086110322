import { auth } from "@/firebaseconfig.js";
import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/components/auth/login.vue";
import Register from "@/components/auth/register.vue";

const Wrapper = () =>
  import(/* webpackChunkName: "Wrapper" */ "@/components/wrapper.vue");

const Home = () =>
  import(/* webpackChunkName: "Home" */ "@/components/home/home.vue");

const Events = () =>
  import(/* webpackChunkName: "Events" */ "@/components/events/events.vue");

const Finance = () =>
  import(/* webpackChunkName: "Finance" */ "@/components/finance/finance.vue");

const Settings = () =>
  import(
    /* webpackChunkName: "Settings" */ "@/components/settings/settings.vue"
  );

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/login",
  },
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login",
      navbar_title: "Home",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      title: "Register",
      navbar_title: "Home",
    },
  },
  // {
  //   path: "/home",
  //   name: "Home",
  //   component: Home,
  //   meta: {
  //     requiresAuth: true,
  //     title: "Home",
  //   },
  // },
  {
    path: "/home",
    component: Wrapper,
    meta: {
      requiresAuth: true,
      title: "Home",
    },
    children: [
      {
        path: "",
        redirect: {
          name: "Home",
        },
      },
      {
        path: "/home",
        name: "Home",
        component: Home,
        meta: {
          title: "Home",
          requiresAuth: true,
          navbar_title: "Home",
        },
      },
      {
        path: "/events",
        name: "Events",
        component: Events,
        meta: {
          title: "Events",
          requiresAuth: true,
          navbar_title: "Events",
        },
      },
      {
        path: "/finance",
        name: "Finance",
        component: Finance,
        meta: {
          title: "Finance",
          requiresAuth: true,
          navbar_title: "Finance",
        },
      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings,
        meta: {
          title: "Settings",
          requiresAuth: true,
          navbar_title: "Settings",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  /* -------------------- Scroll to top when route changed -------------------- */
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const fileWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  if (fileWithTitle) document.title = fileWithTitle.meta.title;

  const currentUser = auth.currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) next("login");
  else if (!requiresAuth && currentUser) next("home");
  else next();
});
export default router;
