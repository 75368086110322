<template>
  <v-dialog v-model="is_loading" persistent width="300">
    <div class="loader-card">
      <i class="fas fa-circle-notch fa-spin fa-3x"></i>
      <span class="my-2">Loading...</span>
    </div>
  </v-dialog>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
