<template>
  <div class="login">
    <div class="flex-center py-12">
      <icon name="logo" class="is-100x100"></icon>
    </div>
    <div class="login__form">
      <h6 class="text-h5 mb-4">Login to your account</h6>
      <form autocomplete="off">
        <v-text-field
          ref="email"
          v-model="email"
          :rules="[rules.required, rules.email]"
          label="Your Email"
          dense
          required
          autofocus
          autocomplete="off"
          outlined
          class="mt-7 mb-3"
        ></v-text-field>
        <v-text-field
          ref="password"
          v-model="password"
          :rules="[rules.required]"
          :type="show_password ? 'text' : 'password'"
          label="Enter Password"
          dense
          required
          outlined
          class="mb-3"
        ></v-text-field>
      </form>
      <div @click="login" class="kvk-button mb-7">
        Login
      </div>
      <div class="kvk-button is-link flex-center mb-7" @click="dialog = true">
        Forgot Password ?
      </div>
      <router-link to="/register" class="flex-center is-link"
        ><span class="helper-text">Dont have an account yet?</span>
        <span class="accent-text ml-2 fw-500 ls-05">Register</span></router-link
      >
    </div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-text class="pt-6">
          <v-text-field
            v-model="forgot_email"
            label="Email*"
            required
            outlined
            dense
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="sendResetEmail">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="error_snackbar"
      multi-line
      :timeout="5000"
      top
      color="error"
    >
      <div class="flex-between">
        <div>
          {{ error }}
        </div>
        <div @click="error_snackbar = false">
          <icon name="x-circle" file="feather" class="is-24x24"></icon>
        </div>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import {
  auth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  memberCollection,
  getDocs,
} from "@/firebaseconfig.js";

export default {
  data() {
    return {
      email: "",
      forgot_email: "",
      password: "",
      show_password: false,
      error: null,
      dialog: false,
      error_snackbar: false,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  methods: {
    login() {
      this.is_loading = true;
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then(async (data) => {
          this.$store.commit("SET_LOGGED_USER", data.user);
          let mem = null;

          //  const q = query(memberCollection, orderBy("name", "asc"));
          const querySnapshot = await getDocs(memberCollection);
          if (querySnapshot.docs && querySnapshot.docs.length) {
            mem = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
          } else {
            console.log("No data");
          }
          this.$store.commit("SET_CLAIMS", ...mem);
          this.is_loading = false;
          this.$router.replace({ name: "home" });
        })
        .catch((err) => {
          this.is_loading = false;
          this.error_snackbar = true;
          if (err.code == "auth/invalid-credential") {
            this.error = "Invalid Credential";
          } else if (err.code == "auth/invalid-email") {
            this.error = "Invalid Email";
          } else if (err.code == "auth/invalid-password") {
            this.error = "Invalid Password";
          } else if (err.code == "auth/user-not-found") {
            this.error = "User not found";
          } else if (err.code == "auth//wrong-password") {
            this.error = "Wrong Password";
          } else {
            this.error = "Something went wrong!!";
          }
          console.log(err.code);
        });
    },
    sendResetEmail() {
      sendPasswordResetEmail(auth, this.forgot_email)
        .then((data) => {
          console.log("logged in successfully", data);
          // Password reset email sent!
          // ..
        })
        .catch((error) => {
          console.log(error.code);
          console.log(error.message);
          // const errorCode = error.code;
          // const errorMessage = error.message;
          // ..
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  background-color: $primary;
  height: 100vh;
  &__form {
    background-color: #fff;
    height: 100%;
    border-radius: 1.25rem 1.25rem 0 0;
    padding: 1.25rem;
  }
}
</style>
