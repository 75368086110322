import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@fortawesome/fontawesome-free/css/all.css";
import Icon from "@/components/shared/ui/icon.vue";
import Currency from "@/components/shared/filters/currency.js";
import HelperMixin from "@/components/shared/mixins/global-mixin.js";
import PrettyDateFormat from "@/components/shared/filters/pretty-date-format.js";
// import vClickOutside from "v-click-outside";
import { auth, onAuthStateChanged } from "@/firebaseconfig.js";

// Vue.use(vClickOutside);
Vue.component("icon", Icon);
Vue.filter("currency", Currency);
Vue.filter("prettyDateFormat", PrettyDateFormat);

Vue.mixin(HelperMixin);
Vue.config.productionTip = false;

let app = "";
onAuthStateChanged(auth, (user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: function(h) {
        return h(App);
      },
    }).$mount("#app");
  }
  store.commit("SET_LOGGED_USER", user);
});
