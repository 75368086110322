import { mapState, mapGetters } from "vuex";
import { memberCollection, getDocs } from "@/firebaseconfig.js";
export default {
  computed: {
    is_loading: {
      get() {
        return this.$store.state.is_content_loading;
      },
      set(value) {
        this.$store.commit("SET_IS_CONTENT_LOADING", value);
      },
    },
  },
  async mounted() {
    if (
      this.$store &&
      this.$store.state.logged_user &&
      Object.keys(this.$store.state.logged_user).length
    ) {
      let mem = null;
      let grouped_member = null;
      const querySnapshot = await getDocs(memberCollection);
      if (querySnapshot.docs && querySnapshot.docs.length) {
        mem = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // console.log(this.$store.state.logged_user);
        grouped_member = _.groupBy(mem, function(t) {
          return t.id;
        });
      } else {
        console.log("No data");
      }
      // this.$store.commit("SET_CLAIMS", ...mem);
      this.$store.commit("SET_CLAIMS", mem);
      this.$store.commit("SET_ALL_MEMBERS", grouped_member);
      this.$store.commit("SET_ALL_MEMBERS_LIST", mem);
    } else {
    }
  },
};
